import { formatCurrency } from '@util/index';
import { ProductDocument, Variation } from 'models/product';

const ProductPrice = ({
  product,
  selectedVariations,
}: {
  product: ProductDocument;
  selectedVariations?: Variation[] | null;
}) => {
  const getPrice = () => {
    if (!selectedVariations) return product.price;
    const letterSize = selectedVariations?.find((v) => isNaN(+v.size));
    const numberSize = selectedVariations?.find(
      (v) => !isNaN(+v.size) && v.is_number
    );
    const isColor = selectedVariations?.find((v) => !!v.color);
    if (letterSize && numberSize && !isColor) {
      return letterSize.price + numberSize.price;
    }
    if (letterSize) {
      if (isColor) {
        return (
          product.variations?.find(
            (v) => v.color === isColor.color && v.size === letterSize.size
          )?.price || letterSize.price
        );
      }
      return letterSize.price;
    }
    if (numberSize) {
      if (isColor) {
        return (
          product.variations?.find(
            (v) => v.color === isColor.color && v.size === numberSize.size
          )?.price || numberSize.price
        );
      }
      return numberSize.price;
    }
    return product.price;
  };
  return product.on_sale ? (
    <div className="flex flex-col items-start gap-[0.8rem]">
      <div className="flex gap-[0.8rem]">
        <p className="text-[1.3rem] font-normal leading-[2.4rem] text-gray-500 line-through">
          {formatCurrency(product.og_price)}
        </p>

        <span className="text-brand-secondary">{`${Math.round(
          ((Number(product.og_price) - product.price) /
            Number(product.og_price)) *
            100
        )}% off`}</span>
      </div>
      <p className="text-h3 font-semibold leading-[2.4rem] text-brand-secondary">
        {formatCurrency(getPrice())}
      </p>
    </div>
  ) : !product.is_auction ? (
    <p className="text-h3 font-semibold leading-[2.4rem] text-brand-primary">
      {formatCurrency(getPrice())}
    </p>
  ) : null;
};

export default ProductPrice;
