import { ChatFeatureIcon } from '@c/icons';
import { ChatDocument } from '@models/chat';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import Spinner from '@ui/Spinner';
import { TabItem } from '@ui/Tabs';
import { getAllUnreadMessages } from '@util/firestore/messages';
import { useTabs } from '@util/hooks/useTabs';
import { useAuth } from 'context/AuthContext';
import { useAdminChats } from 'features/messages/hooks/useAdminChats';
import { FiRefreshCcw } from 'react-icons/fi';
import MessagePreview from './MessagePreview';

const Conversations = ({
  conversations,
  onConversationSelected,
  selectedChat,
  loading,
}: {
  conversations: ChatDocument[];
  onConversationSelected: (chat: ChatDocument) => void;
  selectedChat: ChatDocument | undefined;
  loading?: boolean;
}) => {
  return (
    <>
      {loading ? (
        <div className="flex h-full w-full flex-col items-center justify-center gap-[1.6rem] lg:max-h-[60rem]">
          <ChatFeatureIcon />
          <h3 className="text-[2.4rem] font-bold ">Please wait</h3>
          <p>Loading your messages...</p>
          <div className="mx-auto mt-4 h-10 w-10">
            <Spinner />
          </div>
        </div>
      ) : conversations?.length === 0 ? (
        <div className="flex h-full w-full flex-col items-center justify-center gap-[1.6rem] lg:max-h-[60rem]">
          <ChatFeatureIcon />
          <h3 className="text-[2.4rem] font-bold ">No Messages</h3>
          <p>Your messages will appear here</p>
        </div>
      ) : null}
      {!!conversations.length &&
        conversations.map((conversation, index) => {
          if (conversation.id) {
            return (
              <MessagePreview
                chat={conversation}
                selected={selectedChat?.id === conversation.id}
                key={conversation.id + '-' + index}
                onClick={() => {
                  if (conversation.id) {
                    onConversationSelected(conversation);
                  }
                }}
              />
            );
          }
        })}
    </>
  );
};
type ConversationListProps = {
  onConversationSelected: (chat: ChatDocument) => void;
  conversations: ChatDocument[] | undefined;
  selectedChat: ChatDocument | undefined;
  variant: 'messages' | 'offers' | 'orderDetail' | 'experts';
};

export default function ConversationList({
  onConversationSelected,
  selectedChat,
  conversations,
  variant,
}: ConversationListProps) {
  const { userDoc } = useAuth();
  const {
    adminChats,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
    adminSellerChats,
    isLoadingSellerChats,
    isFetchingSellerChats,
    isFetchingNextPageSellerChats,
    refetchSellerChats,
    adminBuyerChats,
    isLoadingBuyerChats,
    isFetchingBuyerChats,
    isFetchingNextPageBuyerChats,
    refetchBuyerChats,
    onScrollAll,
    onScrollBuyer,
    onScrollSeller,
    fetchNextPage,
    hasNextPageBuyerChats,
    hasNextPageSellerChats,
    hasNextPage,
    fetchNextPageSellerChats,
    fetchNextPageBuyerChats,
    adminFlaggedChats,
    onScrollFlagged,
    totalAdminBuyerChats,
    totalAdminSellerChats,
  } = useAdminChats({ variant });
  const chats = userDoc?.roles?.admin ? [] : conversations;

  const sellerConversations = chats?.filter(
    (conversation) =>
      conversation.seller_id === userDoc?.uid &&
      conversation.uids?.includes(userDoc?.uid)
  );

  const buyerConversations = chats?.filter(
    (conversation) =>
      conversation.buyer_id === userDoc?.uid &&
      conversation.uids?.includes(userDoc?.uid)
  );

  const { data: allUnread } = useQuery({
    queryKey: ['allUnread'],
    queryFn: () => getAllUnreadMessages(),
    enabled: !!userDoc?.roles?.admin && variant === 'messages',
  });

  const tabItems: TabItem[] = [
    {
      title: `Buying ${
        userDoc?.roles?.admin ? `(${totalAdminBuyerChats})` : ''
      }`,
      unreads: buyerConversations?.reduce(
        (acc, chat) => (chat.unread?.[userDoc?.uid ?? ''] ? acc + 1 : acc),
        0
      ),
      contents: (
        <div
          className="grow overflow-y-auto overscroll-y-contain pb-[10rem]"
          onScroll={onScrollBuyer}
        >
          {!!buyerConversations && (
            <Conversations
              conversations={
                userDoc?.roles?.admin && variant === 'messages'
                  ? adminBuyerChats?.pages.flat().filter((c) => !c.is_auto) ??
                    []
                  : buyerConversations ?? []
              }
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
              loading={
                userDoc?.roles?.admin &&
                variant === 'messages' &&
                (isLoadingBuyerChats ||
                  (isFetchingBuyerChats && !isFetchingNextPageBuyerChats))
              }
            />
          )}
          {userDoc?.roles?.admin && hasNextPageBuyerChats && (
            <Button
              loading={isFetchingNextPageBuyerChats}
              type="tertiary"
              text="Fetch More"
              height="small"
              width={'fluid'}
              onClick={() => fetchNextPageBuyerChats()}
            />
          )}
        </div>
      ),
    },
    {
      title: `Selling ${
        userDoc?.roles?.admin ? `(${totalAdminSellerChats})` : ''
      }`,
      unreads: sellerConversations?.reduce(
        (acc, chat) => (chat.unread?.[userDoc?.uid ?? ''] ? acc + 1 : acc),
        0
      ),
      contents: (
        <div
          className="grow overflow-y-auto overscroll-y-contain pb-[10rem]"
          onScroll={onScrollSeller}
        >
          {!!sellerConversations && (
            <Conversations
              conversations={
                userDoc?.roles?.admin && variant === 'messages'
                  ? adminSellerChats?.pages.flat().filter((c) => !c.is_auto) ??
                    []
                  : sellerConversations ?? []
              }
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
              loading={
                userDoc?.roles?.admin &&
                variant === 'messages' &&
                (isLoadingSellerChats ||
                  (isFetchingSellerChats && !isFetchingNextPageSellerChats))
              }
            />
          )}
          {userDoc?.roles?.admin && hasNextPageSellerChats && (
            <Button
              loading={isFetchingNextPageSellerChats}
              type="tertiary"
              text="Fetch More"
              height="small"
              width={'fluid'}
              onClick={() => fetchNextPageSellerChats()}
            />
          )}
        </div>
      ),
    },
    {
      title: `All`,
      // unreads: userDoc
      //   ? conversations?.reduce(
      //       (acc, chat) => (chat.unread?.[userDoc.uid] ? acc + 1 : acc),
      //       0
      //     )
      //   : undefined,
      contents: (
        <div
          className="grow overflow-y-auto overscroll-y-contain pb-[10rem]"
          onScroll={onScrollAll}
        >
          {!!conversations && (
            <Conversations
              conversations={
                userDoc?.roles?.admin &&
                (variant === 'messages' || variant === 'offers')
                  ? adminChats?.pages
                      .flat()
                      .filter(
                        (c) =>
                          !c.is_auto &&
                          ((variant === 'offers' && c.is_offer) ||
                            (variant === 'messages' && !c.is_offer))
                      ) ?? []
                  : conversations ?? []
              }
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
              loading={
                userDoc?.roles?.admin &&
                variant === 'messages' &&
                (isLoading || (isFetching && !isFetchingNextPage))
              }
            />
          )}
          {userDoc?.roles?.admin && hasNextPage && (
            <Button
              loading={isFetchingNextPage}
              type="tertiary"
              text="Fetch More"
              height="small"
              width={'fluid'}
              onClick={() => fetchNextPage()}
            />
          )}
        </div>
      ),
    },
  ];
  if (userDoc?.roles?.admin && variant === 'messages') {
    tabItems.push({
      title: `Unread (temp) ${allUnread?.length ?? 0} `,
      // unreads: userDoc
      //   ? conversations?.reduce(
      //       (acc, chat) => (chat.unread?.[userDoc.uid] ? acc + 1 : acc),
      //       0
      //     )
      //   : undefined,
      contents: (
        <div
          className="grow overflow-y-auto overscroll-y-contain pb-[10rem]"
          onScroll={onScrollAll}
        >
          {!!allUnread && (
            <Conversations
              conversations={allUnread}
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
            />
          )}
        </div>
      ),
    });
    tabItems.push({
      title: 'Flagged',
      contents: (
        <div
          className="grow overflow-y-auto overscroll-y-contain pb-[10rem]"
          onScroll={onScrollFlagged}
        >
          {!!adminFlaggedChats && (
            <Conversations
              conversations={
                adminFlaggedChats?.pages.flat().filter((c) => !c.is_auto) ?? []
              }
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
            />
          )}
        </div>
      ),
    });
  }

  const { currentBody, tabs } = useTabs({ tabItems, full: true, initial: 2 });
  if (!userDoc) return null;

  return (
    <div className="flex h-full flex-col overflow-y-auto border-0 lg:border-r-[1px]">
      <span className="px-[1.6rem] pt-[1.6rem] text-[1.8rem] font-semibold lg:hidden">
        Messages
      </span>

      {userDoc?.roles?.admin && (
        <div className="flex w-full gap-4 p-4">
          <Button
            key="buyer"
            leadingIcon={<FiRefreshCcw width={24} height={24} />}
            text="(buyer)"
            width="fluid"
            height="extraSmall"
            onClick={() => {
              refetchBuyerChats();
            }}
          />
          <Button
            key="seller"
            leadingIcon={<FiRefreshCcw width={24} height={24} />}
            text="(seller)"
            width="fluid"
            height="extraSmall"
            onClick={() => {
              refetchSellerChats();
            }}
          />
          <Button
            key="all"
            leadingIcon={<FiRefreshCcw width={24} height={24} />}
            text="(all)"
            width="fluid"
            height="extraSmall"
            onClick={() => {
              refetch();
            }}
          />
        </div>
      )}
      <div className="shrink">{tabs}</div>
      {currentBody}
    </div>
  );
}
