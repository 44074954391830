import { BrandDocument } from '@models/brand';
import { ProductDocument } from '@models/product';
import { BreadcrumbItem } from '@ui/Breadcrumb';
import Chip from '@ui/Chip';
import { getGenderLabel } from '@util/createListingHelpers';
import { slugify } from '@util/urlHelpers';
import Link from 'next/link';

const ProductOverview = ({
  product,
  brand,
  breadcrumbs,
}: {
  product: ProductDocument;
  brand?: BrandDocument | null;
  breadcrumbs: BreadcrumbItem[];
}) => {
  // function getDefaultSlug(cat: string) {
  //   const catMap = {
  //     'Riding Gear': '/shop/riding-gear',
  //     'Bike Parts': '/shop/parts',
  //     Accessories: '/shop/accessories',
  //     Bikes: '/shop/dirt-bikes',
  //   };
  //   return catMap[cat as keyof typeof catMap] ?? '/shop/all';
  // }
  return (
    <>
      <h2 className="mt-[2rem] text-[1.5rem] font-semibold">Overview</h2>
      <div className="flex w-full flex-col gap-x-16 gap-y-4 whitespace-nowrap">
        <div className="flex flex-row gap-[0.8rem]">
          {!!product.ride_types?.length && (
            <>
              <p className="w-[10rem] text-[1.5rem]">Ride Types: </p>
              <div className="flex w-full flex-wrap gap-[0.8rem] overflow-x-hidden">
                {product.ride_types?.map((c) => (
                  <Chip
                    key={c}
                    text={c}
                    color={'primary'}
                    href={breadcrumbs.find((b) => b.label === c)?.href}
                  />
                ))}
              </div>
            </>
          )}
        </div>
        <div className="flex flex-row">
          <p className="w-[10rem] text-[1.5rem]">Condition:</p>
          <p className="ml-[0.8rem] grow">{product.condition}</p>
        </div>
        {product.gender &&
          (product.category === 'Riding Gear' ||
            (product.category === 'Mountain Bikes' &&
              product.category1 === 'Gear')) && (
            <div className="flex flex-row">
              <p className="w-[10rem] text-[1.5rem]">Gender:</p>
              <p className="ml-[0.8rem] grow">
                {getGenderLabel(product.gender)}
              </p>
            </div>
          )}
        {product.size && (!!product.size.letter || !!product.size.number) && (
          <div className="flex flex-row">
            <p className="w-[10rem] text-[1.5rem]">Size:</p>
            <p className="ml-[0.8rem] grow">
              {product.size?.letter}{' '}
              {product.size?.letter && product.size.number ? '/' : ''}{' '}
              {product.size?.number}
            </p>
          </div>
        )}
        <div className="flex">
          <p className="w-[10rem] shrink-0 text-[1.5rem]">Category:</p>
          <div className="flex w-full flex-wrap gap-[0.8rem] overflow-x-hidden">
            {breadcrumbs.map((b) => (
              <Chip
                key={b.href}
                text={b.label}
                color={'primary'}
                href={b.href}
              />
            ))}
          </div>
        </div>

        <div className="flex">
          <p className="mt-[0.5rem] w-[10rem] text-[1.5rem]">Brand:</p>

          {brand?.url || product.brand === 'OEM' ? (
            <Link
              className="ml-[0.8rem] mt-[0.5rem] grow font-semibold text-brand-secondary"
              href={
                product.brand === 'OEM'
                  ? '/shop/oem-dirt-bike-parts'
                  : `/brands/${brand?.url}`
              }
            >
              {product.brand}
            </Link>
          ) : (
            <Link
              href={`/brands/${slugify(product.brand)}`}
              className="ml-[0.8rem] mt-[0.5rem] grow font-semibold text-brand-secondary"
            >
              {product.brand}
            </Link>
          )}
        </div>
        {!!product.item_number && (
          <div className="flex">
            <p className="mt-[0.5rem] w-[10rem] text-[1.5rem]">Item Number:</p>
            <span className="ml-[0.8rem] mt-[0.5rem] grow">
              {product.item_number}
            </span>
          </div>
        )}
        {product.sku && (
          <div className="flex">
            <p className="mt-[0.5rem] w-[10rem] text-[1.5rem]">SKU/MPN:</p>
            <span className="ml-[0.8rem] mt-[0.5rem] grow">{product.sku}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductOverview;
